<template>
    <ProductListingPage />
</template>

<script>
    export default {
        /**
         * Declaratively register required components.
         *
         * @link https://vuejs.org/v2/guide/components.html#Local-Registration
         */
        components: {
            ProductListingPage: () => import('@components/ProductListingPage'),
        },
    };
</script>
